.mobile-menu {
	width: 100vw;
	height: 100vh;
	position: fixed;
	left: -100vw;
	top: 0;
	display: none;
	z-index: 999;
	background: #17479D;
	overflow-y: auto;
	transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
	&.animate {
		display: block;
	}
	&.active {
		left: 0;
	}
	.container {
		padding: 0 20px;
	}
	.header {
		height: get-vw(74px);
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row-reverse;
		margin-bottom: 16px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.3);
		.close {
			width: get-vw(20px);
			height: get-vw(20px);
			-webkit-mask: url("../images/close.svg") no-repeat center;
			mask: url("../images/close.svg") no-repeat center;
			-webkit-mask-size: get-vw(20px) auto;
			mask-size: get-vw(20px) auto;
			background-color: #fff;
		}
		.back {
			color: #00387e;
			font-size: get-vw(16px);
			display: flex;
			align-items: center;
			background: transparent;
			transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
			opacity: 0;
			&:before {
				width: get-vw(7.64px);
				height: get-vw(13px);
				content: "";
				-webkit-mask: url("../images/tns-arrow.svg") no-repeat center;
				mask: url("../images/tns-arrow.svg") no-repeat center;
				-webkit-mask-size: auto get-vw(13px);
				mask-size: auto get-vw(13px);
				background-color: #00387e;
				margin-right: get-vw(9.9px);
			}
		}
	}
	nav {
		li {
			padding-left: 20px;
			ul {
				padding-left: 20px;
			}
		}
	}
	.lang {
		position: relative;
		display: flex;
		align-items: center;
		span {
			font-size: 16px;
			color: #E2EFF8;
			position: relative;
			cursor: pointer;
			padding-right: 14px;
			display: flex;
			align-items: center;
			@media (max-width: 991px) {
				color: #fff;
				height: 100%;
				display: flex;
				align-items: center;
				font-weight: 400;
				font-size: get-vw(16px);
			}
			&:after {
				content: "";
				margin-left: 6px;
				display: inline-block;
				transition: all 0.5s ease;
				background-color: #fff	;
				-webkit-mask: url("../images/right-arrow.svg") no-repeat center;
				mask: url("../images/right-arrow.svg") no-repeat center;
				-webkit-mask-size: 14px auto;
				mask-size: 14px auto;
				width: 14px;
				height: 14px;
			}
		}
		> div {
			position: absolute;
			right: 0;
			margin-left: -45px;
			top: 27%;
			margin-top: 31px;
			width: 100%;
			background: #fff;
			opacity: 0;
			transition: all 0.5s ease;
			-webkit-transform: perspective(400) rotate3d(1, 0, 0, -90deg);
			-webkit-transform-origin: 50% 0;
			padding: 10px 0;
			z-index: 88;
			box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
			a {
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				font-size: 16px;
				color: #000;
				height: 42px;
				overflow: hidden;
				padding: 0 15px;
				transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
				text-decoration: none;
				&:hover {
					color: #14568C;
					background: #E2EFF5;
				}
			}
		}
		&:hover,
		&.active {
			padding: 10px 0 10px 10px;
			background: #14568c;
			span {
				&::after {
					-webkit-transform: rotate(-180deg);
					-ms-transform: rotate(-180deg);
					transform: rotate(-180deg);
					margin-bottom: 4px;
				}
			}
			> span i {
				-webkit-transform: rotate(-180deg);
				-ms-transform: rotate(-180deg);
				transform: rotate(-180deg);
			}
			> div {
				display: block;
				opacity: 1;
				-webkit-transform: perspective(400) rotate3d(0, 0, 0, 0);
			}
		}
	}
	nav > ul {
		> li {
			transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
			> a {
				color: #D9D9D9;
				font-size: 16px;
				display: flex;
				align-items: center;
				padding: get-vw(16px) 0;
				position: relative;
				line-height: get-vw(16px);
				font-family: 'Open Sans', sans-serif !important;
				transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
				i {
					display: block;
					width: 12px;
					height: 12px;
					-webkit-mask: url("../images/right-arrow.svg") no-repeat center;
					mask: url("../images/right-arrow.svg") no-repeat center;
					-webkit-mask-size: auto 8px;
					mask-size: auto 8px;
					background-color: #fff;
					margin-left: 12px;
					-webkit-transform: rotate(-90deg);
					-ms-transform: rotate(-90deg);
					transform: rotate(-90deg);
				}
			}
			> ul {
				overflow: hidden;
				height: 0;
				transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
				transition: opacity 0.1s cubic-bezier(0.4, 0, 0.2, 1);
				opacity: 0;
				display: flex;
				flex-wrap: wrap;
				li {
					width: 100%;
					padding: 12px 0 12px 0;
					
					a {
						color: #D9D9D9;
						font-size: 12px;
						font-weight: 400;
					}
				}
			}
			&.active {
				padding-top: get-vw(15px);
				> a {
					height: get-vw(44px);
					color: #00BCF1;
					i {
						background-color: #00BCF1;
						-webkit-transform: rotate(0deg);
						-ms-transform: rotate(0deg);
						transform: rotate(0deg);
					}
				}
				>ul {
					height: auto;
					opacity: 1;
				}
			}
			.sub2 {
				>a {
					i {
						display: none;
						width: get-vw(6px);
						height: get-vw(10px);
						-webkit-mask: url("../images/right-arrow.svg") no-repeat center;
						mask: url("../images/right-arrow.svg") no-repeat center;
						-webkit-mask-size: auto get-vw(10px);
						mask-size: auto get-vw(10px);
						background-color: #00BCF1;
						margin-left: 12px;
						-webkit-transform: rotate(-90deg);
						-ms-transform: rotate(-90deg);
						transform: rotate(-90deg);
						@media (max-width: 991px) {
							display: inline-block;
						}
					}
				}
				ul {
					overflow: hidden;
					height: 0;
					transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
					transition: opacity 0.1s cubic-bezier(0.4, 0, 0.2, 1);
					opacity: 0;
					display: flex;
					flex-wrap: wrap;
				}
				&.active {
					>ul {
						height: auto;
						opacity: 1;
					}
				}
			}
		}
	}
	.track-btn {
		background-color: #fff;
		color:#12191F;
		display: flex;
		margin: 30px 20px 0;
		&::after {
			content: '';
			width: 20px;
			height: 20px;
			display: inline-block;
			background-color: #12191F;
			-webkit-mask: url("../images/track.svg") no-repeat center;
			mask: url("../images/track.svg") no-repeat center;
			-webkit-mask-size: 20px auto;
			mask-size: 20px auto;
			margin-left: 12px;
		}
	}
	.social {
		display: flex;
		justify-content: center;
		a {
			margin-left: 0;
			margin: 100px 15px 36px;
		}
	}
}
