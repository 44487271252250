.faq-page {
    padding-top: 15px;
    @media (max-width: 991px) {
        padding-bottom: 20px;
        padding-top: 0;
    }
    .item {
        background: #fff;
        margin-bottom: 16px;
        padding: 16px;
        position: relative;
        box-shadow: 0px 4px 16px rgba(23, 109, 167, 0.1);
        transition: all 0.5s cubic-bezier(.4,0,.2,1);
        > h3 {
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            color: #12191F;
            position: relative;
            cursor: pointer;
            min-height: 60px;
            display: flex;
            align-items: center;
            font-family: 'Open Sans', sans-serif !important;
            &:after {
                content: "";
                display: block;
                width: 24px;
                height: 24px;
                -webkit-mask: url("../images/plus.svg") center no-repeat;
                mask: url("../images/plus.svg") center no-repeat;
                -webkit-mask-size: 20px auto;
				mask-size: 20px auto;
                background-color: #12191F;
                margin-left: 16px;
                transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
            }
        }
        div {
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: #12191F;
            height: 0;
            overflow: hidden;
            transition: all 0.5s cubic-bezier(.4,0,.2,1);
            border-top: 0 solid transparent;
            padding: 0;
            font-family: 'Open Sans', sans-serif !important;
            p + p {
                padding-top: 15px;
            }
        }
        &.open {
            background: #17479D;
            > h3 {
                color: #F5F5F5;
                &:after {
                    -webkit-mask: url("../images/minus.svg") center no-repeat;
                    mask: url("../images/minus.svg") center no-repeat;
                    background-color: #F5F5F5;
                    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
                }
            }
            div {
                height: auto;
                padding: 16px 30px 20px;
                color: #F5F5F5;
                @media (max-width: 991px) {
                    padding: 16px 0 0;
                }
            }
        }
    }
    .pagination {
        margin-top: 60px;
        @media (max-width: 991px) {
            margin-top: 16px;
        }
    }
}
