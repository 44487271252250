.news {
    .title-wrap {
        margin-top: 60px;
    }
    .news-list {
        .item {
            margin-bottom: 32px;
            position: relative;
        }
    }
	.pagination {
		margin-top: 30px;
		@media (max-width: 991px) {
			margin-top: 16px;
		}
	}
}
.news-detail-wrapper {
    margin-top: 32px;
    @media (max-width: 991px) {
        margin-top: 32px;
    }
}
.news-detail {
    .title-wrap {
        margin: 60px 0 16px;
    }
	.date_share {
		margin-bottom: 42px;
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #dae4fa;
	}
	.img {
		margin-right: 20px;
		overflow: hidden;
		@media (max-width: 991px) {
			width: 100%;
			float: none;
		}
		img {
			width: 100%;
		}
	}
	.text {
		font-size: 16px;
        font-weight: 400;
		color: #020608;
		line-height: 25px;
		p,
		div {
			padding: 13px 0;
			&:first-child {
				padding-top: 0;
			}
		}
	}
	.gallery {
		margin-top: 32px;
		.tns-outer {
			.tns-controls button {
                position: absolute;
                top: 40%;
                margin-top: -22px;
                width: 64px;
                height: 64px;	
                background: #fff;
                border-radius: 50%;
                margin: 0 5px;
                border: 1px solid #17479D;
                border-radius: 100px;
                z-index: 1;
                left: -37px;
                @media (max-width: 991px) {
                    margin-top: get-vw(-20px);
                    width: get-vw(64px);
                    height: get-vw(64px);
                }
                &:before {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: #17479D;
                    -webkit-mask: url("../images/tns-arrow.svg") no-repeat center;
                    mask: url("../images/tns-arrow.svg") no-repeat center;
                    @media (max-width: 991px) {
                        -webkit-mask-size: get-vw(8px) auto;
                        mask-size: get-vw(8px) auto;
                    }
                }
            
                // &[disabled] {
                //     background-color: rgba(#282e36, 0.15);
                // }
                &[data-controls="next"] {
                    left: auto;
                    right: -37px;
                    -webkit-transform: rotate(-180deg);
                    -ms-transform: rotate(-180deg);
                    transform: rotate(-180deg);
                }
            }
		}
		a {
			position: relative;
			display: block;
			overflow: hidden;
			border-radius: 0;
			transition: all 0.4s ease;

			img {
				width: 100%;
			}
			&.video {
				position: relative;
				&:after {
					content: "";
					position: absolute;
					display: block;
					left: 50%;
					margin-left: -19px;
					top: 50%;
					margin-top: -19px;
					width: 38px;
					height: 38px;
					border-radius: 50%;
					background: url("../images/play.svg") center no-repeat;
					background-size: 38px auto;
				}
				&:before {
					content: "";
					display: block;
					position: absolute;
					width: 58px;
					height: 58px;
					border-radius: 50%;
					border: 20px solid #ffffff;
					left: 50%;
					margin-left: -29px;
					top: 50%;
					margin-top: -29px;
					z-index: 2;
					-webkit-animation: burst 2s infinite linear;
					animation: burst 2s infinite linear;
				}
			}
		}
	}
}
.date-share {
	display: flex;
	.date {
		font-weight: 400;
        font-size: 14px;
		color: #C6C8D3;
		line-height: 25px;
		display: flex;
		align-items: center;
		margin-right: 27px;
	}
	.share {
		height: 30px;
		border-radius: 15px;
		background: url("../images/fb1.svg") 10px center no-repeat #29207F;
		font-weight: 400;
        font-size: 14px;
		color: #fff;
		align-items: center;
		display: flex;
		padding: 0 10px 0 32px;
		text-decoration: none;
	}
}
.others-news {
	background-color: #ffffff;
	border-radius: 6px;
    box-shadow: 0px 4px 16px rgba(23, 109, 167, 0.1);
	.header {
		color: #12191F;
        font-weight: 600;
		font-size: 24px;
        padding: 32px 0 24px;
        text-align: center;
	}
	.list {
		a {
			display: block;
			color: #000;
			font-size: 18px;
            line-height: 25px;
            font-weight: 400;
			padding: 32px 16px;
            color: #12191F;
            transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
			.title {
                font-weight: 600;
                font-size: 16px;
            }
            .desc {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: rgba(#12191F, .6);
                margin-top: 12px;
                display: block;
            }
            .more {
                margin-top: 22px;
                padding-left: 0;
                font-size: 16px;
            }
            &:hover {
                color: #053B6D;
                background: #FFFFFF;
                box-shadow: 0px 4px 16px rgba(23, 109, 167, 0.1);
                .date {
                    color: #00B1B4;
                }
            }
		}
	}
	@media (max-width: 991px) {
        margin-top: 40px;
	}
}