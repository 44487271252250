.media {
	.image {
		position: relative;
		margin-bottom: 30px;
		display: flex;
		justify-content: center;
		@media (max-width: 991px) {
			margin-bottom: 40px;
		}
		img {
			width: 100%;
			transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
		}
		a {
			height: 44px;
			display: inline-flex;
			align-items: center;
			position: absolute;
			bottom: 0;
			transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
			opacity: 0;
			@media (max-width: 991px) {
				bottom: -22px;
				opacity: 1;
			}
			&:after {
				content: "";
				display: block;
				width: 13px;
				height: 100%;
				margin-left: 10px;
                background-color: #fff;
                -webkit-mask: url("../images/download.svg") center no-repeat;
                mask: url("../images/download.svg") center no-repeat;
                -webkit-mask-size: 13px auto;
				mask-size: 13px auto;
			}
		}
		&:hover {
			img {
				-webkit-filter: blur(2px);
				filter: blur(2px);
			}
			a {
				bottom: -22px;
				opacity: 1;
			}
		}
	}
	.video {
		background: #ffffff;
		overflow: hidden;
		display: block;
		margin-bottom: 30px;
		transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
		.img {
			position: relative;
			display: block;
			img {
				width: 100%;
				position: relative;
			}
			&:after {
				content: "";
				position: absolute;
				display: block;
				left: 50%;
				top: 50%;
                margin-left: -43px;
                margin-top: -43px;
				width: 86px;
				height: 86px;
				border-radius: 50%;
				background: url("../images/play.svg") center no-repeat;
				background-size: 86px auto;
			}
			&:before {
				content: "";
				display: block;
				position: absolute;
				width: 76px;
				height: 76px;
				border-radius: 50%;
				border: 15px solid #ffffff;
				left: 50%;
                margin-left: -38px;
                margin-top: -38px;
				top: 50%;
				z-index: 2;
				-webkit-animation: burst 2s infinite linear;
				animation: burst 2s infinite linear;
			}
		}
		.title {
			display: block;
			color: rgba(#282e36, 0.85);
			font-family: FuturaPT-Book;
			font-size: 17px;
			padding: 0 30px;
		}
		&:hover {
			box-shadow: 0 0 20px rgba(#00387e, 0.2);
		}
	}
	.logo {
		position: relative;
		margin-bottom: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #17479D;
		height: 400px;
		img{
			width: 274px;
		}
		@media (max-width: 991px) {
			margin-bottom: 32px;
		}
		a {
			height: 52px;
            width: 88%;
			display: inline-flex;
			background: #fff;
			border-radius: 43px;
			color: #17479D;
			font-weight: 500;
            font-size: 13px;
			align-items: center;
			position: absolute;
			bottom: 32px;
			transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
		}
        h3 {
            font-weight: 700;
            font-size: 32px;
            line-height: 48px;
            color: #fff;
            text-align: center;
            span {
                display: block;
            }
        }
	}
    .pagination {
        margin-top: 30px;
        @media (max-width: 991px) {
            margin-top: 16px;
        }
    }
}
