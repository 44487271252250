.form {
	.title {
		color: #282e36;
		font-size: 26px;
		margin-bottom: 30px;
		@media (max-width: 991px) {
			margin-bottom: 0;
		}
	}
    div.success {
        color: #00BD44;
        font-weight: 600;
        font-size: 16px;
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 42px;
        transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
        opacity: 0;
        height: 0;
        overflow: hidden;
        &::after {
            content: "";
            display: block;
            width: 24px;
            height: 24px;
            margin-left: 26px;
            background: url("../images/check-mark.svg") center no-repeat;
        }
		@media (max-width: 991px) {
			padding-left: 0;
			&::after {
				width: 63px;
    			height: 26px;
				margin-left: 0;
			}
		}
    }
    form.success {
        div.success {
            opacity: 1;
            height: 44px;
        }
    }
    .select2-container {
        width: 100% !important;
    }
    .select2-selection__rendered {
        height: 44px;
        display: flex!important;
        align-items: center;
    }
    label {
        display: block;
        margin: 0 0 9px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #12191F;
    }
	input[type="text"],
	input[type="email"],
	input[type="password"],
    .select2-container,
	textarea {
		margin-bottom: 30px;
		@media (max-width: 991px) {
			margin-bottom: 15px;
		}
	}
	input[type="text"],
	input[type="email"],
	input[type="password"],
    .select2-selection,
	textarea {
		background-color: #F5F5F5;
		height: 44px;
		border-radius: 0 !important;
		border: 1px solid #F5F5F5;
		color: #282e36;
		font-weight: 400;
        font-size: 13px;
		padding-left: 12px;
		width: 100% !important;
		transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
		&:focus {
			border-color: #17479d;
			box-shadow: 0 5px 10px rgba(#17479d, 0.26);
		}
		@include placeholder {
			color: rgba(#12191F, .6);
		}
		&:-webkit-autofill {
			-webkit-box-shadow: 0 0 0px 1000px #fff inset;
			-webkit-text-fill-color: #282e36 !important;
		}
		&.error {
			border-color: #ff0000;
		}
	}
    .select2-container--default{
        &.select2-container--open {
            .select2-selection__arrow{
                -webkit-transform: rotate(-180deg);
                -ms-transform: rotate(-180deg);
                transform: rotate(-180deg);
                transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
            }
        }
    }
	textarea {
		height: auto;
		padding: 10px 18px;
		transition: all 0.4s ease;
	}

	.radio {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #ffffff;
		height: 46px;
		border-radius: 6px;
		border: 1px solid #dae4fa;
		margin-bottom: 30px;
		font-size: 16px;
		padding-right: 30px;
		> div {
			color: #282e36;
			display: flex;
			align-items: center;
			height: 100%;
			border-right: 1px solid #dae4fa;
			padding-left: 18px;
			padding-right: 17px;
		}
		label {
			display: flex;
			position: relative;
			align-items: center;
			padding-left: 22px;
			input {
				position: absolute;
				visibility: hidden;
				width: 14px;
				height: 14px;
				left: 0;
				top: 50%;
				margin-top: -7px;
				&:checked + span {
					color: #282e36;
					&:before {
						border: 4.5px solid #ffa100;
					}
				}
			}
			span {
				color: #aec3f2;
				&:before {
					content: "";
					display: block;
					position: absolute;
					width: 14px;
					height: 14px;
					left: 0;
					top: 50%;
					margin-top: -7px;
					border: 1px solid #aec3f2;
					border-radius: 50%;
				}
			}
		}
		&.error {
			border-color: #ff0000;
		}
	}
}
