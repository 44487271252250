@charset "utf-8";
@function get-vw($object) {
	$vw: (375 * 0.01) * 1px;
	@return ($object / $vw) * 1vw;
}
@mixin placeholder {
	&::-webkit-input-placeholder {
		@content;
	}
	&:-moz-placeholder {
		@content;
	}
	&::-moz-placeholder {
		@content;
	}
	&:-ms-input-placeholder {
		@content;
	}
}
@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translate3d(0, 100%, 0)
	}
	to {
		opacity: 1;
		transform: none
	}
}
.fadeInUp{
	animation-name: fadeInUp;
}
@keyframes fadeInDown {
	from {
		opacity: 0;
		transform: translate3d(0, -100%, 0)
	}
	to {
		opacity: 1;
		transform: none
	}
}
.fadeInDown{
	animation-name: fadeInDown;
}
@keyframes fadeInLeft {
	from {
		opacity: 0;
		transform: translate3d(-100%, 0, 0)
	}
	to {
		opacity: 1;
		transform: none
	}
}
.fadeInLeft{
	animation-name: fadeInLeft;
}
@keyframes fadeInRight {
	from {
		opacity: 0;
		transform: translate3d(100%, 0, 0)
	}
	to {
		opacity: 1;
		transform: none
	}
}
.fadeInRight{
	animation-name: fadeInRight;
}
.animate{
	-webkit-animation-duration: 1.25s;
	animation-duration: 1.25s;
}

html,
body {
	font-family: 'Poppins', sans-serif;
}
.disable_scroll {
	overflow: hidden;
}
* {
	outline: none;
	text-decoration: none;
	margin: 0px;
	padding: 0px;
	border: 0px;
}
*,
*:before,
*:after {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
a {
	cursor: pointer;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-ms-touch-action: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
	border: none;
}
button:focus::-moz-focus-inner,
input[type="reset"]:focus::-moz-focus-inner,
input[type="button"]:focus::-moz-focus-inner,
input[type="submit"]:focus::-moz-focus-inner,
input[type="file"] > input[type="button"]:focus::-moz-focus-inner {
	border: none;
}

ul,
ul li {
	list-style: none;
}

h1,
h2,
h3,
h4,
h5,
b,
strong {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.container {
	@media (max-width: 991px) {
		width: 100%;
	}
}
#header {
	background: #fff;
	position: fixed;
  width: 100%;
  top: 0;
  z-index: 66;
	height: 93px;
  display: flex;
  align-items: center;
	box-shadow: 0px 32px 60px rgba(112, 144, 176, 0.1);
	@media (max-width: 991px) {
		height: 71px;
		.logo img,.home-logo img{
			height: 32px;
		}
	}

	.home-logo {
		display: none;
	}
	.inner {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		position: relative;
		z-index: 2;
		height: 93px;
		@media (max-width: 991px) {
			height: 71px;
		}
		.ls {
			display: flex;
			align-items: center;
		}
		.nav-wrap {
			nav {
				padding: 0 25px;
				height: 100%;
				position: relative;
				font-family: 'Open Sans', sans-serif !important;
				@media (max-width: 991px) {
					display: none;
				}
				> ul {
					display: flex;
					height: 100%;
					> li {
						margin: 0 25px;
						height: 100%;
						position: relative;
						> a {
							position: relative;
							font-weight: 400;
							font-size: 16px;
							color: #000;
							transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
							display: flex;
							align-items: center;
							height: 100%;
							i {
								display: inline-block;
								background-color: #000;
								-webkit-mask: url(../images/right-arrow.svg) no-repeat center;
								mask: url(../images/right-arrow.svg) no-repeat center;
								-webkit-mask-size: 14px auto;
								mask-size: 14px auto;
								width: 14px;
    							height: 14px;
								margin-left: 5px;
								transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
							}
						}
						&:hover {
							> a {
								color: #17479D;
								i {
									background-color: #17479D;
									-webkit-transform: rotate(-180deg);
									-ms-transform: rotate(-180deg);
									transform: rotate(-180deg);
									transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
								}
								&:after {
									opacity: 1;
									bottom: 16px;
								}
							}
						}
						&.sub {
							&:hover {
								ul {
									opacity: 1;
									-webkit-transform: perspective(400) rotate3d(0, 0, 0, 0) translateX(-50%);
									box-shadow: 0px 0px 60px rgba(112, 144, 176, 0.26);
									transition-delay: 0;
								}
							}
							.sub {
								>a {
									&:after {
										content: "";
										margin-left: 6px;
										display: inline-block;
										transition: all 0.5s ease;
										background-color: #333;
										-webkit-mask: url("../images/right.svg") no-repeat center;
										mask: url("../images/right.svg") no-repeat center;
										width: 20px;
										height: 15px;
										position: absolute;
										right: 16px;
										transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
									}
									&:hover {
										&:after {
											background-color: #14568C;
											transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
										}
									}
								}
								ul {
									position: absolute;
									min-width: 360px;
									background: #fff;
									margin-top: 0;
									text-align: left;
									display: none;
									right: 0;
									left: 100%;
									top: 0;
									-webkit-transform: none!important;
								}
								&.sub2 {
									&:hover {
										ul {
											display: block;
										}
									}
								}
							}
						}
						ul {
							position: absolute;
							left: 50%;
							background-color: #17479D;
							top: 100%;
							opacity: 0;
							z-index: 9;
							min-width: 285px;
							padding: 10px 0;
							transition: all 0.5s ease;
							-webkit-transform: perspective(400) rotate3d(1, 0, 0, -90deg) translateX(-50%);
							-webkit-transform-origin: 50% 0;
							box-shadow: 0px 32px 60px rgba(112, 144, 176, 0.1);
							li {
								width: 100%;
								a {
									display: flex;
									height: 48px;
									align-items: center;
									border-radius: 6px;
									font-weight: 400;
									font-size: 16px;
									transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
									color: #fff;
									border-radius: 0;
									margin: 0 16px;
									position: relative;
									font-family: 'Open Sans', sans-serif !important;
									transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
									&::after {
										content: '';
										height: 1px;
										width: 0;
										display: block;
										background: #00BCF1;
										position: absolute;
										bottom: 0;
										transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
									}
									&:hover {
										&::after {
											width: 100%;
										}
										color: #00BCF1;
									}
								}
								&:nth-child(4n):after {
									display: none;
								}
							}
						}
					}
				}
			}
		}
		.rs {
			display: flex;
			align-items: center;
			.btn {
				color: #F5F5F5;
				background: #12191F;
				transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
				&::after {
					content: '';
					width: 20px;
					height: 20px;
					display: inline-block;
					background-color:#F5F5F5;
					-webkit-mask: url("../images/track.svg") no-repeat center;
					mask: url("../images/track.svg") no-repeat center;
					-webkit-mask-size: 20px auto;
					mask-size: 20px auto;
					margin-left: 12px;
				}
				@media (max-width: 991px) {
					display: none;
				}
				&:hover {
					background: #12191F !important;
					opacity: .8;
				}
			}
		}
		.lang {
			position: relative;
			height: 100%;
			display: flex;
			align-items: center;
			@media (max-width: 991px) {
				min-height: auto;
				display: none;
				margin-right: get-vw(15px);
			}
			span {
				font-size: 16px;
				color: #000;
				position: relative;
				cursor: pointer;
				margin-right: 30px;
				display: flex;
				align-items: center;
				font-family: 'Open Sans', sans-serif !important;
				@media (max-width: 991px) {
					color: #12191f;
					height: 100%;
					display: flex;
					align-items: center;
					font-weight: 500;
					font-size: get-vw(14px);
				}
				&:after {
					content: "";
					margin-left: 6px;
					display: inline-block;
					transition: all 0.5s ease;
					background-color: #000	;
					-webkit-mask: url("../images/right-arrow.svg") no-repeat center;
					mask: url("../images/right-arrow.svg") no-repeat center;
					-webkit-mask-size: 14px auto;
					mask-size: 14px auto;
					width: 14px;
    				height: 14px;
				}
			}
			> div {
				position: absolute;
				left: 50%;
				margin-left: -45px;
				top: 67%;
				margin-top: 31px;
				width: 64px;
				background: #17479D;
				opacity: 0;
				transition: all 0.5s ease;
				-webkit-transform: perspective(400) rotate3d(1, 0, 0, -90deg);
				-webkit-transform-origin: 50% 0;
				padding: 10px 0;
				a {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					font-size: 16px;
					color: #fff;
					height: 42px;
					overflow: hidden;
					margin: 0 15px;
					transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
					text-decoration: none;
					&::after {
						content: '';
						height: 1px;
						width: 0;
						display: block;
						background: #00BCF1;
						position: absolute;
						bottom: 0;
						left: 0;
						transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
					}
					&:hover {
						&::after {
							width: 100%;
						}
						color: #00BCF1;
					}
				}
			}
			&:hover,
			&.active {
				> span i {
					-webkit-transform: rotate(-180deg);
					-ms-transform: rotate(-180deg);
					transform: rotate(-180deg);
				}
				> div {
					display: block;
					opacity: 1;
					-webkit-transform: perspective(400) rotate3d(0, 0, 0, 0);
					box-shadow: 0px 0px 60px rgba(112, 144, 176, 0.26);
				}
			}
		}
		.dropdown {
			position: relative;
			height: 100%;
			display: flex;
			align-items: center;
			@media (max-width: 991px) {
				min-height: auto;
				display: none;
				margin-right: get-vw(15px);
			}
			>a {
				font-size: 16px;
				color: #E2EFF8;
				position: relative;
				cursor: pointer;
				padding-right: 14px;
				display: flex;
				align-items: center;
				@media (max-width: 991px) {
					color: #021d39;
					height: 100%;
					display: flex;
					align-items: center;
					font-weight: 500;
					font-size: get-vw(14px);
				}
				&:after {
					content: "";
					margin-left: 6px;
					display: inline-block;
					transition: all 0.5s ease;
					background-color: #E2EFF8;
					-webkit-mask: url("../images/right-arrow.svg") no-repeat center;
					mask: url("../images/right-arrow.svg") no-repeat center;
					width: 8px;
					height: 5px;
				}
			}
			> div {
				position: absolute;
				left: 0;
				margin-left: -45px;
				top: 100%;
				width: auto;
				min-width: 244px;
				background: #fff;
				opacity: 0;
				transition: all 0.5s ease;
				-webkit-transform: perspective(400) rotate3d(1, 0, 0, -90deg);
				-webkit-transform-origin: 50% 0;
				padding: 10px 0;
				a {
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 6px;
					cursor: pointer;
					font-size: 16px;
					color: #000;
					height: 44px;
					overflow: hidden;
					transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
					text-decoration: none;
					&:hover {
						color: #14568C;
						background: rgba(#00387e, 0.1);
					}
				}
			}
			&:hover,
			&.active {
				> a i {
					-webkit-transform: rotate(-180deg);
					-ms-transform: rotate(-180deg);
					transform: rotate(-180deg);
				}
				> div {
					display: block;
					opacity: 1;
					-webkit-transform: perspective(400) rotate3d(0, 0, 0, 0);
				}
			}
		}
	}
	.open-mobile-menu {
		display: none;
		@media (max-width: 991px) {
			display: block;
			width: get-vw(25px);
			height: get-vw(18px);
			background-color: #000;
			-webkit-mask: url(../images/mobil-menu-icon.svg) no-repeat center;
			mask: url(../images/mobil-menu-icon.svg) no-repeat center;
		}
	}
}
.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 48px;
	padding: 0 32px;
	font-weight: 500;
	font-size: 13px;
	text-transform: uppercase;
	border-radius: 43px;
	background-color: #17479D;
	color: #fff;
	letter-spacing: 1.25px;
	transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
	&:hover {
		opacity: .8;
	}
	&.bg-white {
		&:hover {
			background-color: #17479D !important;
			color: #fff !important;
			&::after {
				background-color: #fff !important;
			}
		}
	}
}
.home {
	#header {
		position: relative;
		box-shadow: none;
		background: transparent;
		animation-name: fadeInDown;
		-webkit-animation-duration: 1.25s;
		animation-duration: 1.25s;
		.inner {
			.nav-wrap {
				nav {
					> ul {
						> li {
							> a {
								color: #fff;
								i {
									background-color: #fff;
								}
								&:hover {
									color: #00BCF1;
									i {
										background-color: #00BCF1;
									}
								}
							}
							&.sub {
								&:hover {
									ul {
										opacity: 1;
										-webkit-transform: perspective(400) rotate3d(0, 0, 0, 0) translateX(-50%);
										transition-delay: 0;
									}
								}
							}
						}
					}
				}
			}
			.rs {
				.btn {
					background-color: #fff;
					color:#12191F;
					&::after {
						background-color: #12191F;
						transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
					}
				}
			}
			.lang {
				span {
					color: #fff;
					&:after {
						background-color: #fff;
					}
				}
			}
		}
		.open-mobile-menu {
			@media (max-width: 991px) {
				background-color: #fff;
			}
		}
	}
	.home-logo {
		display: block !important;
	}
	.logo {
		display: none;
	}
	#footer {
		margin-top: 0 !important;
	}
}

.bg-home {
	height: 100vh;
	min-height:620px;
	position: relative;
	margin-top: -93px;
	background: url("../images/home-bg.png") center no-repeat;
	background-size: cover;
	@media (max-width: 480px) {
		height: auto;
		min-height: get-vw(461px);
	}
	@media only screen and (min-width: 480px) and (max-width: 991px) {
		height: 650px;
	}
	@media (max-width: 991px) {
		background-position: 60% center !important;
		margin-top: -71px;
	}
	.inner{
		position: relative;
		z-index: 1;
		padding-top: 70px;
		padding-bottom: 70px;
		width: 100%;
		height: 100vh;
		min-height:620px;
		display: flex;
		align-items: center;
		@media (max-width: 480px) {
			height: auto;
			min-height: get-vw(461px);
			padding-bottom: 130px;
		}
	}
	.home-content {
		max-width: 720px;
		animation-name: fadeInUp;
		-webkit-animation-duration: 1.25s;
		animation-duration: 1.25s;
		.title {
			font-weight: 600;
			font-size: 58px;
			line-height: 87px;
			color: #fff;	
			margin-bottom: 20px;
			span {
				display: block;
				color: #7eebd2;
			}
		}
		.desc {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			color: #E3E3E3;
			display: block;
			font-family: 'Open Sans';
			max-width: 535px;
		}
		.btn {
			color: #12191F;
			background-color: #fff;
			margin-top: 32px;
		}
		@media (max-width: 991px) {
			max-width: get-vw(270px);
			padding-top: get-vw(44px);
			.title {
				font-size: get-vw(32px);
				line-height: get-vw(48px);
				margin-bottom: get-vw(16px);
			}
			.desc {
				font-size: get-vw(16px);
				line-height: get-vw(24px);
			}
		}
	}
}
.text-right {
	text-align: right !important;
}
.text-center {
	text-align: center !important;
	justify-content: center !important;
}
.text-left {
	text-align: left !important;
}

#footer {
	padding-top: 40px;
	background:#12191F;
	margin-top: 120px;
	@media (max-width: 991px) {
		margin-top: 62px;
	}
	.footer_side {
		p {
			padding: 12px 102px 25px 0;
			color: #B8D7E9;
			font-size: 16px;
			line-height: 24px;
		}
	}
	.f-nav-title {
		color: #fff;
		font-size: 20px;
		font-weight: 300;
		margin-bottom: 15px;
		margin-top: 13px;
    	display: block;
		@media (max-width: 991px) {
			margin-top: get-vw(50px);
		}
	}
	.cl {
		a {
			color: #fff;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			display: block;
			margin-top: 12px;
			transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
			font-family: 'Open Sans';
			&:hover {
				color: #85bad9;
			}
		}
	}
	.bottom {
		margin-top: 32px;
		.bottom_flex {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 60px;
			color: #fff;
			border-top: 1px solid rgba(255, 255, 255, 0.6);
			font-family: 'Open Sans';
			font-size: 16px;
			@media (max-width: 991px) {
				display: block;
				height: auto;
				flex-direction: column;
				align-items: center;
				line-height: 25px;
				font-size: get-vw(16px);
				padding: 20px 0;
				> div {
					line-height: 25px;
				}
				.social {
					margin-top: 20px;
					a {
						margin-left: 0;
						margin-right: 32px;
					}
				}
			}
			a.marcom {
				color: #fff;
				font-weight: 400;
			}
		}
	}
	.col-md-3 {
		>a {
			font-weight: 600;
			font-size: 24px;
			color: #fff;
			@media (max-width: 991px) {
				margin-top: 32px;
				display: block;
			}
		}
	}
}
.social {
	a {
		height: 24px;
		width: 24px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin-left: 30px;
		background-color: #fff;
		-webkit-mask-size: 20px auto;
		mask-size: 20px auto;
		transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
		&.facebook {
			-webkit-mask: url("../images/fb.svg") no-repeat center;
			mask: url("../images/fb.svg") no-repeat center;
		}
		&.instagram {
			-webkit-mask: url("../images/instagram.svg") no-repeat center;
			mask: url("../images/instagram.svg") no-repeat center;
		}
		&.youtube {
			-webkit-mask: url("../images/youtube.svg") no-repeat center;
			mask: url("../images/youtube.svg") no-repeat center;
		}
		&:hover {
			opacity: .7;
		}
		i {
			color: #fff;
		}
	}
}

@media (max-width: 991px) {
	.mo-0 {
		order: 0;
	}
	.mo-1 {
		order: 1;
	}
	.mo-2 {
		order: 2;
	}
	.mo-3 {
		order: 3;
	}
}
/* BURST */
@-webkit-keyframes burst {
	0% {
		opacity: 0.5;
	}

	50% {
		-webkit-transform: scale(1.7);
		transform: scale(1.7);
		opacity: 0;
	}

	100% {
		opacity: 0;
	}
}

@keyframes burst {
	0% {
		opacity: 0.5;
	}

	50% {
		-webkit-transform: scale(1.7);
		transform: scale(1.7);
		opacity: 0;
	}

	100% {
		opacity: 0;
	}
}
.page-header {
	height: 300px;
	width: 100%;
	overflow: hidden;
	position: relative;
	display: flex;
    align-items: center;
	margin-bottom: 66px;
	background: url(../images/page-header.svg) center no-repeat;
	&:after {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		bottom: 0;
		z-index: 1;
		background: rgba(#12191F, .8);
		
	}
	@media (max-width: 991px) {
		height: 300px;
		padding-top: 0;
		display: flex;
		align-items: center;
		margin-bottom: get-vw(32px);
		background-position: 65% center;
	}
	.title {
		color: #fff;
		font-weight: 700;
		font-size: 32px;
		z-index: 5;
		position: relative;
		text-align: center;
		text-transform: uppercase;
		@media (max-width: 991px) {
			padding-bottom: 0;
			padding-left: 35px;
			font-size: 32px;
			padding-left: 0;
		}
	}
}
.tabs {
	display: flex;
	align-items: center;
	justify-content: center;
	color: rgba(#282e36, 0.3);
	font-size: 20px;
	a,
	button {
		padding: 0 38px;
		height: 35px;
		display: flex;
		align-items: flex-start;
		justify-content: center;
	}
	margin-bottom: 40px;
}
.tabs_content {
	> * {
		display: none;
		&.active {
			display: block;
		}
	}
}
.page-wrapper {
	position: relative;
	margin-top: 93px;
	min-height: calc(100vh - 350px);
	@media (max-width: 991px) {
		margin-top: 71px;
	}
}
.fancybox-bg {
	background: rgba(#053B6D, 0.95);
}
.pagination {
	text-align: center;
	display: flex;
	justify-content: center;
	ul {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
	}

	li {
		padding: 0 13px;
		@media (max-width: 991px) {
			padding: 0 3px;
		}
		&.active {
			a {
				color: #17479D;
			}
		}
	}
	a {
		color: #D0D8DB;
		font-weight: 500;
		font-size: 22px;
		text-decoration: none;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 32px;
		width: 32px;
		border-radius: 6px;
		@media (max-width: 991px) {
			font-size: 15px;
		}
		&:hover {
			color: #17479D;
		}
		&.prev,
		&.next {
			position: relative;
			width: 64px;
			height: 64px;
			border: 1px solid #17479D;
			border-radius: 100px;
			display: flex;
			align-items: center;
			justify-content: center;
			@media (max-width: 991px) {
				width: 40px; 
				height: 40px;
			}
			&:after {
				content: "";
				display: block;
				width: 100%;
				height: 100%;
				background-color: #053B6D;
				-webkit-mask: url("../images/tns-arrow.svg") no-repeat center;
				mask: url("../images/tns-arrow.svg") no-repeat center;
				-webkit-mask-size: 5.29px auto;
				mask-size: 5.29px auto;
			}
			&.disabled:after {
				background-color: #C6C6C6;
			}
		}
		&.next {
			&:after {
				-webkit-transform: rotate(-180deg);
				-ms-transform: rotate(-180deg);
				transform: rotate(-180deg);
			}
		}
	}
}
.menu {
	background-color: #ffffff;
	padding: 42px 16px ;
	margin-bottom: 30px;
	border: 1px solid #C6C6C6;
	@media (max-width: 991px) {
		background-color: transparent;
	}
	h2 {
		&.title {
			font-size: 20px;
			font-weight: 700;
			line-height: 28px;
			color: #000;
		}
	}
	a {
		display: flex;
		align-items: center;
		color: #282e36;
		padding: 10px 0;
		justify-content: space-between;
		transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
		&:hover {
			color: #00387e;
		}
	}
	> li {
		border-radius: 6px;
		transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
		margin-bottom: 2px;
		> a {
			font-size: 14px;
			line-height: 20px;
			color: #717171;
		}
		ul {
			li {
				a {
					font-size: 15px;
					opacity: 0;
					border-top: 0 solid #ffffff;
					overflow: hidden;
					height: 0;
				}
				&.active a {
					color: #00387e;
				}
			}
		}
		&.active {
			background-color: rgba(#00387e, 0.1);
			> a {
				color: #00387e;
				&:after {
					-webkit-transform: rotate(-180deg);
					-ms-transform: rotate(-180deg);
					transform: rotate(-180deg);
					background-color: #00387e !important;
				}
			}
			ul a {
				opacity: 1;
				border-top-width: 1px;
				height: 44px;
			}
		}
		&.sub > a:after {
			content: "";
			display: block;
			width: 8px;
			height: 5px;
			-webkit-mask: url("../images/right-arrow.svg") center no-repeat;
			mask: url("../images/right-arrow.svg") center no-repeat;
			background-color: #282e36;
			transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
		}
	}
	// &.fixed {
	// 	position: fixed;
	// 	max-width: 268px;
	// 	top: 110px;
	// }
}
.pb10 {
	padding-bottom: 10px;
}
.pb20 {
	padding-bottom: 20px;
}
.pb30 {
	padding-bottom: 30px;
}
@keyframes lds-ripple {
	0% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 1;
	}
	100% {
		top: 0px;
		left: 0px;
		width: 72px;
		height: 72px;
		opacity: 0;
	}
}
.not-found-wrapper {
	background-color: #fff;
}
.not-found {
	padding: 93px 0 0;
	color: #717171;
	font-size: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	@media (max-width: 991px) {
		padding: 0 15px;
	}
	img {
		margin-bottom: 20px;
		@media (max-width: 991px) {
			width: 100%;
			margin: 62px 0 40px;
		}
	}
	.title {
		color: #12191F;
		font-weight: 700;
		font-size: 32px;
		font-family: 'Open Sans', sans-serif !important;
	}
	.desc {
		color: #4B4B4B;
		font-size: 18px;
		line-height: 28px;
		padding: 16px  0;
		text-align: center;
		font-family: 'Open Sans', sans-serif !important;
	}
	>div {
		display: flex;
		flex-direction: column;
		@media (max-width: 991px) {
			align-items: center;
			.title {
				font-size: 24px;
			}
			.desc {
				padding-right: 0;
				text-align: center;
			}
		}
	}
}
a.back-to-top {
	display: block;
	width: 56px;
	height: 56px;
	border-radius: 50%;
	position: fixed;
	right: 50%;
	bottom: 150px;
	margin-right: -700px;
	background: url("../images/right-arrow.svg") center no-repeat #00387e;
	box-shadow: 0 0 20px #00387e;
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
	z-index: 10;
	opacity: 0;
	visibility: hidden;
	transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
	@media (max-width: 991px) {
		margin-right: -23px;
		width: 46px;
		height: 46px;
		background-size: auto 10px;
		box-shadow: 0 0 15px #274d8c;
		bottom: 165px;
	}
	&.show {
		opacity: 1;
		visibility: visible;
	}
}
@media (max-width: 991px) {
	.flex-mobile {
		display: flex;
		flex-direction: column-reverse !important;
	}
}
.home-partners {
	padding: 45px 32px 40px 32px;
	margin-top: -92px;
	z-index: 5;
	position: relative;
	background: #FFFFFF;
	box-shadow: 0px 4px 16px rgba(23, 109, 167, 0.1);
	@media (max-width: 991px) {
		padding: 16px;
	}
	.title-wrap {
		margin-bottom: 0 !important;
		@media (max-width: 991px) {
			align-items: flex-start;
		}
	}
	.title {
		font-weight: 500 !important;
		font-size: 19px !important;
		color: #17479D !important;
		margin-bottom: 20px;
	}
}

.video-block {
	margin-top: 120px;
	@media (max-width: 991px) {
		margin-top: 62px;
	}
	.title-wrap {
		margin-bottom: 0 !important;
		@media (max-width: 991px) {
			align-items: flex-start;
			h2 {
				&.title {
					margin-top: 0;
				}
			}
		}
		.title {
			font-weight: 500 !important;
			font-size: 19px !important;
			color: #17479D;
		}
	}
	.title {
		font-weight: 600;
		font-size: 24px;
		line-height: 36px;
		color: #12191F;
		margin-top: 14px;
	}
	.col-md-6 {
		span {
			display: block;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			margin-top: 14px;
			color: rgba(18, 25, 31, 0.6);
		}
		.btn {
			margin-top: 32px;
			@media (max-width: 991px) {
				margin-bottom: 32px;
			}
		}
		img {
			width: 100%;
		}
		.gallery {
			@media (min-width: 991px) {
				position: relative;
				&::after {
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					position: absolute;
					background: #F5F5F5;
					bottom: -38px;
					right: -38px;
				}
			}
		}
	}
}
.title-wrap {
	margin-bottom: 32px;
	.title {
		font-weight: 700;
		font-size: 32px;
		text-transform: uppercase;
		color: #12191F;
		@media (max-width: 991px) {
			font-size: 24px;
		}
	}
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 991px) {
		flex-direction: column;
	}
	.btn-wrap {
		min-width: 300px;
		display: flex;
		justify-content: flex-end;
	}
	.desc {
		font-weight: 400;
		font-size: 16px;
		color: rgba(18, 25, 31, 0.6);
		margin-top: 16px;
		display: block;
	}
}
.gallery {
	a {
		position: relative;
		display: block;
		overflow: hidden;
		transition: all 0.4s ease;
		z-index: 6;
		img {
			width: 100%;
		}
		&.video {
			position: relative;
			&:after {
				content: "";
				position: absolute;
				display: block;
				left: 50%;
				top: 50%;
                margin-left: -43px;
                margin-top: -43px;
				width: 86px;
				height: 86px;
				border-radius: 50%;
				background: url("../images/play.svg") center no-repeat;
				background-size: 86px auto;
			}
			&:before {
				content: "";
				display: block;
				position: absolute;
				width: 76px;
				height: 76px;
				border-radius: 50%;
				border: 15px solid #ffffff;
				left: 50%;
                margin-left: -38px;
                margin-top: -38px;
				top: 50%;
				z-index: 2;
				-webkit-animation: burst 2s infinite linear;
				animation: burst 2s infinite linear;
			}
		}
	}
}
.home-graph {
	height: 889px;
	position: relative;
	margin-top: 120px;
	padding-top: 155px;
	background: url("../images/home-graph.png") center no-repeat;
	background-size: cover;
	@media (max-width: 991px) {
		margin-top: 62px;
		padding-top: 52px;
		height: 1277px;
		background-size: cover;
	}
	.item-row {
		position: relative;
		display: flex;
		justify-content: space-between;
		&::before {
			content: '';
			height: 1px;
			width: 100%;
			display: block;
			position: absolute;
    		top: 50%;
			background: #fff;
		}
		@media (max-width: 991px) {
			flex-wrap: wrap;
			margin: 0 -15px;
			&::before {
				display: none;
			}
			.l1, .l2, .l3 {
				position: relative;
				&::after {
					content: '';
					height: 1px;
					width: 100%;
					display: block;
					position: absolute;
					top: 50%;
					background: #fff;
				}
			}
		}
		.l1, .l2 {
			flex-basis: 40%;
			@media (max-width: 991px) {
				margin-bottom: 32px;
				.item {
					&:last-child {
						text-align: right;
						&::before {
							right: 0;
						}
						.flex {
							justify-content: flex-end;
						}
					}
				}
			}
		}
		.l3 {
			flex-basis: 20%;
			.item {
				@media (min-width: 991px) {
					&::after {
						content: '';
						width: 13px;
						height: 13px;
						background: #fff;
						border-radius: 50%;
						position: absolute;
						top: 47.5%;
						right: 0;
					}
				}
			}
		}
		.l1, .l2, .l3 {
			display: flex;
			@media (max-width: 991px) {
				flex-basis: 100% !important;
			}
		}
		.item {
			width: 100%;
			@media (max-width: 991px) {
				width: 50%;
				margin: 0 15px;
			}
			position: relative;
			&::before {
				content: '';
				width: 13px;
				height: 13px;
				background: #fff;
				border-radius: 50%;
				position: absolute;
				top: 47.5%;
			}
			img {
				margin-bottom: 33px;
			}
			.flex, .count {
				display: flex;
				font-weight: 700;
				font-size: 32px;
				color: #fff;
				text-transform: uppercase;
				span {
					margin-left: 10px;
				}
			}
			.flex {
				padding-top: 33px;
			}
			.label {
				font-weight: 400;
				font-size: 16px;
				color: #fff;
				margin-top: 8px;
			}
		}
	}
}
.home-news {
	margin-top: -396px;
    position: relative;
    z-index: 5;
	@media (max-width: 991px) {
		position: relative;
		padding-bottom: 62px;
		.title-wrap {
			height: auto!important;
			.btn-wrap {
				position: absolute;
				bottom: 0;
				justify-content: center;
			}
		}
	}
	.title-wrap {
		height: 106px;
		.title {
			color: #fff;
		}
		.desc {
			color: rgba(255, 255, 255, 0.6);
		}
		@media (min-width: 991px) {
			.btn {
				background: #fff;
				color: #12191F;
			}
		}
	}
}
.news-list {
	.item {
		position: relative;
		display: flex;
		flex-direction: column;
		padding-bottom: 32px;
		box-shadow: 0px 4px 16px rgba(23, 109, 167, 0.1);
		.catg {
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #fff;
			border-radius: 34px;
			font-weight: 400;
			font-size: 16px;
			position: absolute;
			top: 16px;
			right: 16px;
			padding: 0 12px;
		}
		.img {
			img {
				width: 100%;
			}
		}
		* {
			font-size: 16px;
			line-height: 24px;
		}
		.title {
			color: #12191F;
			margin-top: 16px;
			padding: 0 16px;
			font-weight: 600;
		}
		.desc {
			font-weight: 400;
			margin-top: 12px;
			padding: 0 16px;
			color: rgba(18, 25, 31, 0.6);
			font-family: 'Open Sans', sans-serif !important;
		}
		margin-bottom: 20px;
	}
}
.more {
	font-weight: 400;
	display: flex;
	align-items: center;
	color: #17479D;
	padding: 0 16px;
	margin-top: 22px;
	font-family: 'Open Sans', sans-serif !important;
	&::after {
		content: '';
		width: 20px;
		height: 20px;
		display: inline-block;
		background-color: #17479D;
		-webkit-mask: url(../images/right.svg) no-repeat center;
		mask: url(../images/right.svg) no-repeat center;
		-webkit-mask-size: 20px auto;
		mask-size: 20px auto;
		margin-left: 12px;
	}
}
.order {
	margin-top: 120px;
	@media (max-width: 991px) {
		margin-top: 62px;
		.media {
			margin: 0 -15px;
			padding: 20px 15px 32px !important;
		}
		button {
			&.btn {
				padding: 0 20px;
			}
		}
	}
	.media {
		padding: 32px;
		background: #FFFFFF;
		box-shadow: 0px 4px 16px rgba(23, 109, 167, 0.1);
		@media (min-width: 991px) {
			>.row {
				display: flex;
				align-items: center;
				.form {
					margin-top: -15px;
				}
			}
		}
		.col-md-6 {
			img {
				width: 100%;
				@media (max-width: 991px) {
					margin-bottom: 32px;
				}
			}
		}
		.form {
			.foot {
				display: flex;
				align-items: center;
			}
		}
	}
}
.tracking {
	height: 420px;
	position: relative;
	margin-top: 120px;
	background: url("../images/tracking.svg") center no-repeat;
	background-size: cover;
	.container {
		height: 100%;
	}
	.tracking-bg {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.title {
			font-weight: 500;
			font-size: 48px;
			line-height: 72px;
			color: #fff;
		}
		.desc {
			font-weight: 600;
			font-size: 24px;
			line-height: 36px;
			color: #fff;
			margin-top: 16px;
		}
		.btn {
			margin-top: 32px;
		}
		@media (max-width: 991px) {
			align-items: center;
			.title {
				font-weight: 600;
				font-size: 24px;
			}
			.desc {
				font-weight: 600;
				font-size: 16px;
				line-height: 24px;
				text-align: center;
			}
		}
	}
	@media (max-width: 991px) {
		margin-top: 62px;
		height: 308px;
	}
}
.text-page {
	color: #4B4B4B;
	div, p {
		margin-bottom: 16px;
		font-weight: 400;
		font-size: 18px;
		line-height: 28px;
		&:last-child {
			margin-bottom: 0;
		}
		@media (max-width: 991px) {
			font-size: get-vw(18px);
			line-height: get-vw(28px);
		}
	}
}
.boss {
	.title-wrap {
		.title {
			text-transform: none;
		}
		@media (max-width: 991px) {
			margin-bottom: 16px;
			.title {
				font-size: 24px;
			}
		}
	}
	.text-page {
		div {
			&:first-child {
				margin-top: 0;
			}
		}
	}
	.item {
		margin: 10px 0 0;
		.img {
			img {
				width: 100%;
			}
		}
		.content {
			padding: 24px 16px;
			background: #fff;
			box-shadow: 0px 4px 16px rgba(23, 109, 167, 0.1);
			.title {
				font-weight: 600;
				font-size: 24px;
				line-height: 36px;
				color: #12191F;
			}
			.desc {
				font-weight: 400;
				font-size: 18px;
				line-height: 28px;
				color: rgba(#12191F, .6);
			}
		}
		@media (max-width: 991px) {
			margin: 32px 0 0;
		}
	}
}
.tns-outer {
	position: relative;
}
.tns-nav {
	button {
		width: 16px;
		height: 16px;
		background: #F5F5F5;
		border-radius: 50%;
		margin: 0 5px;
	}
}
.tns-controls button {
	position: absolute;
	top: 45%;
	margin-top: -22px;
	width: 64px;
	height: 64px;	
	background: #fff;
	border-radius: 50%;
	margin: 0 5px;
	border: 1px solid #17479D;
	border-radius: 100px;
	z-index: 1;
	left: 0;
	@media (max-width: 991px) {
		margin-top: get-vw(-20px);
		width: get-vw(64px);
		height: get-vw(64px);
	}
	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		background-color: #fff;
		-webkit-mask: url("../images/tns-arrow.svg") no-repeat center;
		mask: url("../images/tns-arrow.svg") no-repeat center;
		@media (max-width: 991px) {
			-webkit-mask-size: get-vw(8px) auto;
			mask-size: get-vw(8px) auto;
		}
	}

	&[disabled] {
		background-color: rgba(#282e36, 0.15);
	}
	&[data-controls="next"] {
		left: auto;
		right: 0;
		-webkit-transform: rotate(-180deg);
		-ms-transform: rotate(-180deg);
		transform: rotate(-180deg);
	}
}