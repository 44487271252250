.contact {
    .contact-details {
        margin-bottom: 60px;
        .item {
            padding: 16px 32px;
            background: #FFFFFF;
            display: flex;
            box-shadow: 0px 4px 16px rgba(23, 109, 167, 0.1);
            &::before {
                content: "";
                display: inline-flex;
                width: 39px;
                height: 38px;
                border-radius: 50%;
                margin-right: 16px;
            }
            &.email {
                &::before {
                    background: url("../images/mail.svg") center no-repeat #29207F;
                }
            }
            &.phone {
                &::before {
                    background: url("../images/phone.svg") center no-repeat #29207F;
                }
            }
            &.pin {
                &::before {
                    background: url("../images/pin.svg") center no-repeat #29207F;
                }
            }
            >div {
                flex: 1;
            }
            .title {
                font-weight: 500;
                font-size: 19px;
                color: #12191F;
                text-transform: uppercase;
                margin-bottom: 2px;
            }
            ul {
                li {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: rgba(#12191F, .6);
                    font-family: 'Open Sans', sans-serif !important;
                }
            }
        }
        .col-md-4 {
            @media (max-width: 991px) {
                &:not(:last-child) {
                    .item {
                        margin-bottom: 32px;
                    }
                }
            }
        }
    }
    .form {
        .foot {
            display: flex;
            justify-content: flex-end;
            .btn {
                margin-left: 32px;
                @media (min-width: 991px) {
                    min-width: 250px;
                }
            }
            @media (max-width: 991px) {
                flex-direction: column-reverse;
                align-items: center;
                .success {
                    justify-content: center;
                }
                .btn {
                    width: 100%;
                    margin-left: 0;
                    margin-bottom: 16px;
                }
            }
        }
    }
}