.service {
    .page-header {
        height: 600px;
        margin-bottom: 0;
        background: url(../images/page-header1.svg) center no-repeat;
        background-size: cover;
        @media (max-width: 991px) {
            height: 310px;
            align-items: flex-start;
            .title {
                padding-top: 96px;
            }
        }
    }
    .catg-row {
        margin-top: -89px;
        position: relative;
        z-index: 5;
        
        border-top: 1px solid #4B4B4B;
        .catg {
            margin-bottom: 0;
            height: 88px;
            display: flex;
            justify-content: space-between;
        }
        a {
            padding: 32px;
            height: auto;
            color: #F5F5F5;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            margin-top: -1px;
            border-top: 1px solid transparent;
            transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
            &.active {
                color: #00BCF1;
                border-color:#00BCF1;
            }
        }
        @media (max-width: 991px) {
            overflow-x: scroll;
            margin-top: -88px;
            a {
                margin-top: .0;
                &:first-child {
                    padding-left: 0;
                }
            }
            .catg {
                min-width: 945px;
                justify-content: flex-start;
            }
        }
    }
    .catg-content {
        margin-top: 90px;
        .title-wrap {
            .title {
                font-size: 24px;
            }
        }
        >.item {
            margin-bottom: 60px;
            .text-page {
                div {
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
            .img {
                @media (max-width: 991px) {
                    margin-top: 32px;
                }
            }
        }
        .row {
            .item {
                padding: 32px 16px;
                background: #FFFFFF;
                margin-bottom: 32px;
                transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
                box-shadow: 0px 4px 16px rgba(23, 109, 167, 0.1);
                .title {
                    font-weight: 600;
                    font-size: 24px;
                    color: #12191F;
                    &::before {
                        content: '';
                        width: 20px;
                        height: 20px;
                        display: inline-block;
                        background-color: #00BCF1;
                        -webkit-mask: url(../images/right-arrow.svg) no-repeat center;
                        mask: url(../images/right-arrow.svg) no-repeat center;
                        -webkit-mask-size: 13px auto;
                        mask-size: 13px auto;
                        margin-right: 12px;
                        transform: rotate(-90deg);
                    }
                }
                .desc {
                    font-weight: 400;
                    font-size: 18px;
                    color: #4B4B4B;
                    margin-top: 8px;
                    font-family: 'Open Sans', sans-serif !important;
                }
                &:hover, &:focus {
                    background: #17479D;
                    box-shadow: none;
                    .title {
                        color: #F5F5F5;
                    }
                    .desc {
                        color: #D9D9D9;
                    }
                }
            }
        }
        @media (max-width: 991px) {
            margin-top: 62px;
            .title-wrap {
                margin-bottom: 16px;
                &.text-center {
                    margin-bottom: 32px;
                }
            }
            .row {
                .item {
                    .title {
                        font-size: 19px;
                    }
                }
            }
        }
    }
}