.mission {
    .tabs-header {
        display: flex;
        justify-content: center;
    }
    .tabs {
        background: #17479D;
        width: auto;
        height: 66px;
        margin: auto;
        margin-bottom: 96px;
        padding: 0 5px;
        border-radius: 33px;
        display: inline-flex;
        justify-content: space-between;
        a {
            height: 56px;
            color: #fff;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            padding: 0 32px;
            transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
            &.active {
                background: #00BCF1;
                border-radius: 33px;
            }
        }
        @media (max-width: 991px) {
            margin-bottom: 32px;
        }
    }
    .tabs_content {
        @media (min-width: 991px) {
            .row {
                .col-md-6 {
                    &:nth-child(2n+1) {
                        border-right: 1px solid #00BCF1;
                        .item {
                            display: flex;
                            flex-direction: column-reverse;
                            .img {
                                margin-top: 0 !important;
                                margin-bottom: 32px;
                            }
                        }
                    }
                    &:first-child, &:nth-child(2) {
                        .item {
                            padding-top: 0 !important;
                        }
                    }
                }
            }
        }
    }
    .item {
        @media (min-width: 991px) {
            padding-top: 62px;
        }
        .img {
            margin-top: 32px;
            img {
                width: 100%;
            }
        }
        .title {
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 8px;
        }
        .desc {
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            color: #4B4B4B;
        }
        @media (max-width: 991px) {
            padding-bottom: 32px;
            display: flex;
            flex-direction: column-reverse;
            .img {
                margin-top: 0;
                margin-bottom: 24px;
            }
        }
    }
}