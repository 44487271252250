.quality-policy{
	.title-wrap {
        flex-direction: column;
        align-items: flex-start;
        @media (max-width: 991px) {
            .title {
                font-size: 24px;
            }
        }
    }
	.item{
		padding: 16px;
		color: #54514B;
		font-weight: 400;
        font-size: 18px;
        line-height: 28px;
		margin-bottom: 20px;
        background: #fff;
        box-shadow: 0px 4px 16px rgba(23, 109, 167, 0.1);
		@media (max-width: 991px) {
			padding: 15px 20px;
		}
		b{
			font-weight: 500;
		}
		p + p{
				padding-top: 15px;
		}
	}
}