.parking {
    .slider-block {
        display: flex;
        margin-bottom: 32px;
        &:last-child {
            margin-bottom: 0;
        }
        .slider-side {
            width: 45%;
            padding: 32px 16px 32px 32px;
            box-shadow: 0px 4px 16px rgba(23, 109, 167, 0.1);
            .title {
                font-weight: 600;
                font-size: 24px;
            }
            .rw {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 16px;
                font-weight: 400;
                font-size: 18px;
                label {
                    display: flex;
                    align-items: center;
                    &::before {
                        content: '';
                        width: 20px;
                        height: 20px;
                        display: block;
                        margin-right: 20px;
                        background-color: #00BCF1;
                        -webkit-mask: url(../images/check.svg) no-repeat center;
                        mask: url(../images/check.svg) no-repeat center;
                        -webkit-mask-size: 20px auto;
                        mask-size: 20px auto;
                    }
                }
            }
        }
        .tns-outer {
            width: 55%;
            position: relative;
            .tns-nav {
                position: absolute;
                bottom: 20px;
                z-index: 6;
                width: 100%;
                text-align: center;
                button {
                    &.tns-nav-active {
                        background: #00BCF1;
                    }
                }
            }
            .tns-item {
                img {
                    width: 100%;
                }
            }
        }
        @media (max-width: 991px) {
            flex-direction: column;
            .tns-outer {
                width: 100%;
            }
            .slider-side {
                width: 100%;
                padding: 16px 20px 32px;
            }
        }
    }
}