.document {
    .title-wrap {
        flex-direction: column;
        align-items: flex-start;
    }
    .item {
        padding: 16px;
        background: #F5F5F5;
        margin-bottom: 32px;
        .btn {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            background-color: #F5F5F5;
            border: 1px solid #17479D;
            margin-left: 32px;
            transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
            &::before {
                content: '';
                width: 20px;
                height: 20px;
                display: block;
                background-color: #17479D;
                -webkit-mask: url(../images/eye.svg) no-repeat center;
                mask: url(../images/eye.svg) no-repeat center;
                -webkit-mask-size: 20px auto;
                mask-size: 20px auto;
                transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
            }
            &:hover {
                background-color: #17479D;
                &::before {
                    background-color: #fff;
                }
            }
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            color: #4B4B4B;
            flex: 1;
        }
        @media (max-width: 991px) {
            margin-bottom: 16px;
            .btn {
                margin-left: 20px;
            }
        } 
    }
}