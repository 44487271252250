.about {
    .item {
        margin-bottom: 80px;
        &:last-child {
            margin-bottom: 0;
        }
				&:first-child .row{
					align-items: flex-start;
				}
        .row {
            display: flex;
            align-items: center;
        }
        .title {
            font-weight: 700;
            font-size: 32px;
            line-height: 48px;
            margin-bottom: 16px;
        }
        .img {
            img {
                width: 100%;
            }
        }
        &.flex {
            display: flex;
            position: relative;
            margin-bottom: 142px;
            .content {
                padding: 32px;
                background: #FFFFFF;
                z-index: 3;
                box-shadow: 0px 4px 16px rgba(23, 109, 167, 0.1);
                .title {
                    text-transform: uppercase;
                }
                @media (min-width: 991px) {
                    position: absolute;
                    width: 58%;
                    right: 0;
                    bottom: -32px;
                }
            }
            @media (max-width: 991px) {
                flex-direction: column;
                margin-bottom: 60px;
                .content {
                    margin: -130px 16px 0;
                    padding: 32px 16px;
                    .title {
                        font-size: get-vw(24px);
                        font-weight: 600;
                        line-height: get-vw(36px);
                    }
                }
            }
        }
        @media (max-width: 991px) {
            margin-bottom: 60px;
            .row {
                flex-direction: column-reverse;
                .img {
                    margin-bottom: 16px;
                }
            }
            .title {
                font-size: get-vw(24px);
                font-weight: 600;
                line-height: get-vw(36px);
            }
        }
    }
}